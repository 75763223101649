import {
    IconAddressBook,
    IconCashRegister,
    IconDatabaseCog,
    IconHome,
    IconReportMoney,
    IconSettings,
} from '@tabler/icons-react'
import { Accordion } from '@/components/ui/accordion'
import NavItem from '@/components/nav-item'
import { useState } from 'react'
import SidebarMenuTitle from '@/components/sidebar-menu-title'
import DropdownItem from '@/components/dropdown-item'

export default function SidebarMenu() {
    const [open, setOpen] = useState(false)
    return (
        <nav className="grid items-start  text-sm font-medium ">
            <Accordion type={'single'} collapsible>
                <SidebarMenuTitle text={'Navigasi'} />
                <NavItem
                    text={'Dashboard'}
                    url={route('dashboard')}
                    icon={
                        <IconHome
                            stroke={1.25}
                            className={'text-slate-800 group-hover:text-black'}
                        />
                    }
                />
                <NavItem
                    text={'Customer'}
                    url={route('customer.index')}
                    icon={
                        <IconAddressBook
                            stroke={1.25}
                            className={'text-slate-800 group-hover:text-black'}
                        />
                    }
                />
                <NavItem
                    text={'Transaksi'}
                    url={route('transaction.index')}
                    icon={
                        <IconCashRegister
                            stroke={1.25}
                            className={'text-slate-800 group-hover:text-black'}
                        />
                    }
                />
                <NavItem
                    text={'Laporan Transaksi'}
                    url={route('dashboard')}
                    icon={
                        <IconReportMoney
                            stroke={1.25}
                            className={'text-slate-800 group-hover:text-black'}
                        />
                    }
                />
                <SidebarMenuTitle text={'Sistem'} />
                <DropdownItem
                    icon={<IconDatabaseCog strokeWidth={1.25} />}
                    text={'Data Master'}
                    target={'data-mater'}
                >
                    <DropdownItem.Content
                        url={route('account.index')}
                        text={'Rekening'}
                    />
                    <DropdownItem.Content
                        url={route('jenis-transaksi.index')}
                        text={'Jalur Transaksi'}
                    />
                    <DropdownItem.Content
                        url={route('admin-fee.index')}
                        text={'Biaya Transaksi'}
                    />
                </DropdownItem>
                <NavItem
                    text={'Pengaturan'}
                    url={route('dashboard')}
                    icon={
                        <IconSettings
                            stroke={1.25}
                            className={'text-slate-800 group-hover:text-black'}
                        />
                    }
                />
            </Accordion>
        </nav>
    )
}
