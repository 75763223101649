'use client'
import { IconBolt, IconChevronDown } from '@tabler/icons-react'
import SimpleBar from 'simplebar-react'
import SidebarMenu from '@/components/sidebar-menu'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useState } from 'react'
import { Link } from '@inertiajs/react'

const Sidebar = () => {
    const [open, setOpen] = useState(false)
    return (
        <div className="p-3">
            <div className="flex h-14 items-center px-3 justify-start lg:h-[60px] bg-white rounded-xl border">
                <DropdownMenu>
                    <DropdownMenuTrigger
                        className={
                            'flex items-center justify-between gap-2 w-full font-semibold group'
                        }
                    >
                        <div className="section-left gap-2 flex items-center">
                            <div className="icon-wrapper p-2 bg-gradient-to-tr from-blue-600 to-blue-400 rounded-md group-hover:rotate-12 duration-200 transition-all  gorup-hover:scale-110">
                                <IconBolt
                                    size={24}
                                    className={'text-white'}
                                    strokeWidth={1.25}
                                />
                            </div>
                            <div className="text-box flex items-start justify-start flex-col gap-0">
                                <h1 className="font-semibold mb-0">
                                    Quick Nota
                                </h1>
                                <p className={'text-xs font-light'}>
                                    Buku Pencatatan
                                </p>
                            </div>
                        </div>
                        <IconChevronDown size={18} />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className={
                            'min-w-[235px] border-slate-100 shadow-sm mt-4 w-full'
                        }
                    >
                        <DropdownMenuLabel>My Account</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>Profile</DropdownMenuItem>
                        <DropdownMenuItem>Billing</DropdownMenuItem>
                        <DropdownMenuItem>Team</DropdownMenuItem>
                        <DropdownMenuItem>Subscription</DropdownMenuItem>
                        <DropdownMenuItem className={'text-danger'}>
                            <Link
                                href={route('logout')}
                                className={'text-rose-600'}
                                method={'post'}
                                as={'button'}
                            >
                                Keluar
                            </Link>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <SimpleBar
                forceVisible="y"
                style={{ maxHeight: '70vh' }}
                className="flex-1"
            >
                <SidebarMenu />
            </SimpleBar>
        </div>
    )
}

export default Sidebar
