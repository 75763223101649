import React, { PropsWithChildren, ReactNode } from 'react'
import { User } from '@/types'
import 'simplebar-react/dist/simplebar.min.css'
import Sidebar from '@/layouts/sidebar'
import Footer from '@/layouts/footer'
import { Head } from '@inertiajs/react'
import { Toaster } from 'sonner'

export default function Authenticated({
    user,
    header,
    title,
    children,
}: PropsWithChildren<{
    user?: User
    header?: ReactNode
    title: string
}>) {
    return (
        <>
            <div className="grid min-h-screen md:grid-cols-[220px_1fr] lg:grid-cols-[250px_1fr]">
                <div className="hidden text-slate-800 md:block">
                    <div className="flex md:max-w-[220px] lg:max-w-[260px] w-[260px] bg-[#F6F8FA]/80 fixed h-full max-h-screen flex-col gap-2">
                        <Sidebar />
                    </div>
                </div>
                <div className="flex flex-col">
                    <Head title={title} />
                    <main className="flex flex-1 bg-[#F6F8FA]/80 flex-col -mt-3 gap-4 shadow-none p-0 py-2 md:pt-6 md:px-4">
                        <div className="bg-white rounded md:rounded-xl border-slate-200/80 min-h-[90vh] overflow-hidden shadow-none md:shadow-sm border-slate-200 border-none md:border ">
                            <div className="flex flex-col">{children}</div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
            <Toaster
                position={'top-center'}
                richColors={true}
                theme={'light'}
                closeButton={true}
                toastOptions={{
                    classNames: {
                        error: 'bg-rose-100/50 bg-red-400 border border-rose-700',
                        success:
                            'bg-grean-100/50 text-green-400 border border-green-700',
                        warning:
                            'text-yellow-400 border border-yellow-700 bg-yellow-100/50 ',
                        info: 'bg-blue-100/50 text-blue-400 border border bg-blue-400',
                    },
                }}
            />
        </>
    )
}
